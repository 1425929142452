
import { Vue } from "vue-class-component";
import { Watch } from "vue-property-decorator";

export default class HomeView extends Vue {
  public declare $refs: {
    room: HTMLImageElement;
  };

  public width = "500";
  public height = "500";

  public corner = 0;
  public center = 0;
  public side = 0;

  public cr = 0;
  public mh = 40;

  public map: number[][] = [];

  public show = false;

  mounted() {
    window.addEventListener("resize", this.onResize);
  }

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  }

  public onResize() {
    if (this.show) {
      this.update();
    }
  }

  @Watch("width")
  public onWidthChanged(newValue: string, oldValue: string) {
    if (/^[0-9]{0,3}$/g.test(newValue)) {
      this.width = newValue;
    } else {
      this.width = oldValue;
    }
  }

  @Watch("height")
  public onHeightChanged(newValue: string, oldValue: string) {
    if (/^[0-9]{0,3}$/g.test(newValue)) {
      this.height = newValue;
    } else {
      this.height = oldValue;
    }
  }

  public onSubmitClicked() {
    this.corner = 0;
    this.center = 0;
    this.side = 0;

    this.map = [];

    this.show = false;

    if (this.width == "" || this.height == "") {
      return;
    }

    let width = parseInt(this.width);
    let height = parseInt(this.height);

    if (width < 100 || width > 999 || height < 100 || height > 999) {
      return;
    }

    let centerRow = Math.floor((width - 100) / 50);
    let centerCol = Math.floor((height - 100) / 50);

    this.corner = 4;
    this.center = centerRow * centerCol;
    this.side = (centerRow + centerCol) * 2;

    for (let i = 0; i < centerRow + 2; i++) {
      let row = [];

      if (i == 0 || i == centerRow + 2 - 1) {
        for (let j = 0; j < centerCol + 2; j++) {
          if (j == 0 || j == centerCol + 2 - 1) {
            row.push(0);
          } else {
            row.push(1);
          }
        }
      } else {
        for (let j = 0; j < centerCol + 2; j++) {
          if (j == 0 || j == centerCol + 2 - 1) {
            row.push(1);
          } else {
            row.push(2);
          }
        }
      }

      this.map.push(row);
    }

    this.cr = centerCol;

    this.update();

    this.show = true;
  }

  public update() {
    this.mh = (this.$refs.room.height * 0.65) / (this.cr + 2);
  }
}
